<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>
  <div id="maincontent_container">
        <spinner v-if="loading" />
        <div v-if="message" class="alert alert-success">{{message}}</div>
        <div v-if="error" class="alert alert-danger">{{error}}</div>

        <div v-if="item" class="row mt-4 mb-4">
          <!-- image slide -->
          <div class="col-md-3 mb-5">
            <!-- Carousel wrapper -->

            <!-- Modal -->
            <div class="modal fade" id="carousel_big_image" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg">
                <div class="modal-content">
                  <div class="modal-body">

                    <div>
                      <div 
                        id="carouselExampleIndicators1"
                        class="carousel carousel-dark"
                        data-bs-ride="carousel"
                        data-bs-interval="false">

                          <div class="carousel-inner">
                              <div class="carousel-item" v-for="(image,index) in images.images" :class="{active:index==0}" :key="image">
                                  <img 
                                  :src="image.url"
                                  class="d-block w-100"
                                  />                            
                              </div>
                          </div>

                          <div>
                              <button
                              class="carousel-control-prev"
                              type="button"
                              data-bs-target="#carouselExampleIndicators1"
                              data-bs-slide="prev" style="margin-left:-50px;"
                              >
                              <span
                                  class="carousel-control-prev-icon"
                                  aria-hidden="true"
                              ></span>
                              <span class="visually-hidden">Previous</span>
                              </button>
                              <button
                              class="carousel-control-next"
                              type="button"
                              data-bs-target="#carouselExampleIndicators1"
                              data-bs-slide="next" style="margin-right:-50px;"
                              >
                              <span
                                  class="carousel-control-next-icon"
                                  aria-hidden="true"
                              ></span>
                              <span class="visually-hidden">Next</span>
                              </button>
                          </div>
                      </div>
                  </div>
                    
                  </div>
                  
                </div>
              </div>
            </div>
            <!-- End Modal -->

            <div
              id="carouselExampleIndicators"
              class="carousel carousel-dark slide"
              data-bs-ride="carousel"
              data-bs-interval="false">

                <div class="carousel-inner">
                  <a href="#" data-bs-toggle="modal" data-bs-target="#carousel_big_image">
                    <div class="carousel-item" v-for="(image,index) in images.images" :class="{active:index==0}" :key="image">
                        <img 
                        :src="image.url"
                        class="d-block w-100"/>                            
                    </div>
                  </a>

                    
                </div>

                <div id="carousel_small_images">
                    <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                    >
                    <span
                        class="carousel-control-prev-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                    </button>
                    <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                    >
                    <span
                        class="carousel-control-next-icon"
                        aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                    </button>
                </div>

              <div 
                class="carousel-indicators"
                id="small_images"
              >
              <div v-for="(image,index) in images.images" :key="image">
                  <div v-if="index == 0">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        :data-bs-slide-to="index"
                        class="active"
                        aria-current="true">
                        <img
                            class="d-block w-100 img-fluid"
                            :src="image.url"
                        />
                      </button>
                  </div>
                  <div v-else>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        :data-bs-slide-to="index">
                        <img
                            class="d-block w-100 img-fluid"
                            :src="image.url"
                        />
                      </button>
                  </div>                     
              </div>                   
              </div>
            </div>
            <!-- Carousel wrapper -->

          </div>
          <!-- end of image slide -->

          <!-- description -->
          <div class="col-md-6 ps-5 pe-5">
            <div class="lead fs-3 fw-bold mb-4">
              {{item.title}} 
            </div>
            
            <div class="mb-5 spec">{{item.spec}}</div>
          
            
            <div class=""><span>Category: </span> <span>{{item.category}}</span></div>

            <br>
          </div>
          <!-- end of description -->

          <!-- price -->
          <div class="col-md-3">
            <div class="sticky price_container float-end">
              <div>
                  <div v-if="is_auth">
                    <div class="">Estimated Total Price:</div>
                    <div v-if="loading_price" class="mt-3 mb-5 text-primary">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Calculating...
                    </div>
                    <div v-else class="mt-3 mb-3">
                      <div v-if="inventory">
                        <div class="fs-2">{{formatPrice(total_price)}}</div>
                      </div>
                      <div v-else class="fs-4">N/A</div>
                    </div>
                    <div class="fs-6 mt-2 mb-3 fw-lighter">Price is subject to change upon confirmation of your Ma Labs sales represantive.</div>

                    <div v-if="inventory">
                      <div v-if="is_customer">
                        <table id="stock" class="table table-sm table-secondary" >
                          <thead>
                          <tr>
                              <th>Qty.</th>
                              <th></th>
                              <th>Stock</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                              <td class="p-1"><input class="form-control qty_input" type="text" v-model="quantity" /></td>
                              <td>of</td>
                              <td align="center">{{inventory}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>
                        <table id="stock" class="table table-sm table-secondary" >
                          <thead>
                          <tr>
                              <td align="center" class="fw-bold">Stock</td>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                              <td align="center">{{inventory}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div v-if="is_auth == false" class="mb-4 text-danger">
                      <i class="bi bi-exclamation-triangle-fill font_small me-2"></i> 
                      For existing customers, please log in before submitting the quote request to get prompt support from your Ma Labs account manager.
                      <router-link :to="{name: 'Login'}">Login</router-link>
                       
                       <button v-if="waiting_quote" class="btn btn-primary col-12 mb-2 mt-4 quote_button" type="button" disabled>
                        <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        ></span> 
                          Get Quote
                      </button>
                       <button type="button" class="btn btn-primary col-12 mb-2 mt-4 quote_button" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                        Get Quote
                       </button>
                    </div>
                    <div v-else>
                      <div v-if="is_customer">
                        <button v-if="waiting_quote" class="btn btn-primary col-12 mb-2 quote_button" type="button" disabled>
                          <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                          ></span> 
                            Get Quote
                        </button>
                        <button v-else class="btn btn-primary col-12 mb-2 quote_button" type="button" @click="handleGetQuote">Get Quote</button>
                      </div>
                    </div>
                  </div>

                  <div v-if="is_customer">
                    <div v-if="total_price > 0">
                      <button v-if="waiting_add" class="btn btn-primary col-12 mb-2 add_button" type="button" disabled> 
                        <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span> 
                      Add to Cart
                      </button>
                      <button v-else class="btn btn-primary col-12 add_button" type="button" @click="handleAddToCart">Add to Cart</button>
                    </div>
                  </div>
                  
                    
              </div>
            </div> 
                           
          </div>
          <!-- end of price -->
        </div>


<!--  Popup Layer -->
<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel"><i class="bi bi-exclamation-triangle-fill"></i> Please check!</h5>
        <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
      </div>
      <div class="modal-body">
        <div>
          For existing customers, please log in before continuing.
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary quote_button" data-bs-dismiss="modal" @click="handleLogin">Login</button>
        <button type="button" class="btn btn-primary quote_button" data-bs-dismiss="modal" @click="handleGetQuote">Continue</button>
      </div>
    </div>
  </div>
</div>
<!--  End of Popup Layer-->



        <!-- Configuration -->
        <div v-if="components">
          <!-- <ul class="nav nav-tabs">
            <li class="nav-item">
              <a class="nav-link active nav-link1 pt-3 pb-3 ps-5 pe-5 fs-4" aria-current="page" href="#">Configuration</a>
            </li>
          </ul> -->
          <div class="lead fw-bold fs-3 mb-3">Configuration</div>

          <div class="accordion">
          
          

          <div v-for="(group_item, group) in components" class="accordion-item">
            <h2 class="accordion-header" :id="'heading-'+group">
              <button class="accordion-button spec_bar" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse-'+group" aria-expanded="true" :aria-controls="'collapse'+group">
                <span class="fw-bold fs-4 blue">{{group}}</span>
                <span v-if="group_list[group].optional" class="ms-3 blue">(Optional)</span>
              </button>
            </h2>
            <div :id="'collapse-'+group" class="accordion-collapse collapse show" :aria-labelledby="'heading-'+group">
              <div class="accordion-body m-4">
                
                <div v-if="group_list[group] != undefined && group_list[group].spec" class="fs-5 alert alert-info">{{group_list[group].spec}}</div>
                <div v-if="group_list[group] != undefined && group_list[group].max_qty>0" class="fs-5 mb-4 alert alert-warning">
                  <i class="bi bi-exclamation-triangle-fill"></i> The maximum total quantity of items you can choose for {{group}} is {{group_list[group].max_qty}}.
                </div>

                <div v-if="group_list[group].optional"><a class="pointer" @click="handleUnselectAll(group)">Clear your selections</a></div>

                <div v-for="(type_item, type) in group_item.types">
                  <div class="mb-3 fw-bold">{{type}}</div>
                  
                  <div class="mb-3">
                    <div v-for="item in type_item.items" :id="'div_'+ item.itmno">
                      <div>
                        <table>
                          <tr>
                            <td valign="top">
                              <div class="me-3">
                                <div v-if="item.allow_multi_select">
                                <input v-if="item.selected" checked type="checkbox" :name="group" :value="item.item_id" :id="item.item_id" @change="onCheckboxChange(group, item.item_id)" class="form-check-input mb-3"> 
                                <input v-else type="checkbox" :name="group" :value="item.item_id" :id="item.item_id" @change="onCheckboxChange(group, item.item_id)" class="form-check-input mb-3"> 
                                </div>
                                <div v-else>
                                <input v-if="item.selected" checked type="radio" :name="group" :value="item.item_id" :id="item.item_id" @change="onRadioChange(group, item.item_id)" class="form-check-input mb-3"> 
                                <input v-else type="radio" :name="group" :value="item.item_id" @change="onRadioChange(group, item.item_id)" class="form-check-input mb-3"> 
                                </div>
                              </div>
                            </td>
                            <td valign="top">
                              <div class="mb-1">
                                <div>{{item.title}}
                                  | SKU #: <span>{{item.itmno}}</span> 
                                  <span v-if="item.mfgpartno"> | Mfr #: {{item.mfgpartno}}</span> 
                                  <span v-if="item.barcode"> | UPC: {{item.barcode}} </span>
                                  <span v-if="!item.balance" class="outofstock_flag"><i class="bi bi-exclamation-triangle-fill"></i> expect delay</span> 
                                </div>
                                <div v-if="item.addon_itmno"><span class="badge bg-success">Add-on Item</span> {{item.addon_item.title}} | {{item.addon_itmno}}
                                  <span v-if="!item.addon_item.balance" class="outofstock_flag"><i class="bi bi-exclamation-triangle-fill"></i> expect delay</span>
                                </div>
                                <div v-if="item.allow_multi_qty && item.selected">
                                  <div class="input-group input-group-sm mt-1 mb-3">Qty:
                                    <button class="btn ms-2 qty_btn" type="button" @click="handleDecreaseQty(item.item_id)" :disabled="item.qty <= 1"><i class="bi bi-dash big"></i></button>
                                    <input type="text" class="qty_input_sm" :value="item.qty" :id="'qty_input_'+item.item_id" disabled>
                                    <button class="btn qty_btn" type="button" @click="handleIncreaseQty(item.item_id)" :disabled="item.qty == item.max_qty && item.max_qty != 0 || item.qty == item.balance"><i class="bi bi-plus big"></i></button>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                      
                      
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>

          
          
        </div>
        </div>

        
  </div>
</template>

<script>
import Spinner from "../../components/Spinner.vue"
import BreadcrumbPage from "../../components/BreadcrumbPage.vue"
import { ref } from 'vue'
import { useRoute, useRouter } from "vue-router"
import { useStore } from "vuex";
import { getAuthAPI, getAPI } from "../../utils/axios-api"
import { formatPrice, isInteger } from '../../utils/common'
import { capitalize } from '../../utils/common';
import $ from 'jquery'

export default {
  name: "ConfigDetails",
  components: { BreadcrumbPage, Spinner},
  props: ['config_payload', 'components'], //config_payload and components are used when is page is requested from Get Quote page
  setup(props, context) {
    const loading = ref(true)
    const loading_price = ref(false)
    const error = ref(null)
    const message = ref(null)
    const waiting_quote = ref(false)
    const waiting_add = ref(false)
    const item = ref(null)
    const components = ref(null)
    const inventory = ref(0)
    const total_price = ref(0.0)
    const images = ref({"images":[]})
    const quantity = ref(null)
    const group_list = ref(null)

    //set quantity if the page is returned from quote page
    if ( props.config_payload != undefined && props.config_payload != "") {
      quantity.value = JSON.parse(props.config_payload).quantity
    }

    var component_items = {}
    var selected_items = []

    const store = useStore()
    let isAuthenticated = store.getters.isAuthenticated
    let detailsAPI = null
    if (isAuthenticated == true) detailsAPI = getAuthAPI
    else detailsAPI = getAPI

    const is_customer = ref(store.getters.isCustomer)
    const is_auth = ref(isAuthenticated)


    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Configuration"})

    //get url parameters
    const route = useRoute()
    const router = useRouter()

    //baseid
    var url = ""
    var item_no = route.query.itmno
    if (typeof route.query.itmno != "undefined" && route.query.itmno != "" && route.query.itmno != null ) {
      url = "/product/system/?itmno=" + item_no
    }
    if (url){
      
      detailsAPI
      .get(url)
      .then((response) => {
        item.value = response.data.results

        //set breadcrumb link 
        let category_mapping = {
                        "Server": "server",
                        "Workstation": "desktop"
                        }
        if (category_mapping.hasOwnProperty(item.value.parent_category)) {
          breadcrumb_links.value.push({text: capitalize(category_mapping[item.value.parent_category]) + " Configuration", url: "/configuration/"+category_mapping[item.value.parent_category]})
        }
        else {
          breadcrumb_links.value.push({text: item.value.parent_category})
        }  // breadcrumb_links.value.push({text: item.value.category})
        breadcrumb_links.value.push({text: item.value.title})

        //get all items including addon-items into a dictionary list, itmno is key
        component_items = get_items(item.value.item_list)
        
        //construct component list which is displayed in template for configuration
        components.value = get_component_list(item.value.item_list)
        
        //get the list of groups into a dictionary list, group name is key
        group_list.value = get_group_list(item.value.group_list)

        //if there is components props, it means it is returned from Get Quote page for changing configuration.
        if ( props.components != undefined && props.components != "") {
          components.value = JSON.parse(props.components)
        }
        
        //retrieve inventory and price.
        if (isAuthenticated == true) get_inventory_and_price()

        //get images
        images.value["images"] = get_images(item.value.image)
 

        loading.value = false
      })
      .catch((err) => {
        try {
          error.value = err.response.data.message
        }
        catch {
          error.value = err//"Something is wrong."
        }
        loading.value = false
      })
    }
    else {
      error.value = "Item No is missing."
      loading.value = false
    }
    
    //iterate through the item list returned from API and add each item into a dictionary list, itmno is key.
    function get_items(item_list) {
      var items = {}
      for (var i = 0; i < item_list.length; i++) {
        items[item_list[i].itmno] = item_list[i]
      }
      return items
    }
    
    
    function get_inventory_and_price(){
      //initialize data
      loading_price.value = true
      inventory.value = 0
      total_price.value = 0

      //generate payload
      var payload = {}
      payload["itmno"] = item.value.itmno
      //note: here we need to use JSON.stringify to convert dictionary type into string. Otherwise, server side will get error.
      payload["system_details"] = JSON.stringify({item_list: generate_payload_item_list()})
      
      //call API to get price and inventory
      detailsAPI.post('/product/system/', payload)
      .then((response) => {
        inventory.value = response.data.inventory
        total_price.value = response.data.price
        if (inventory.value == 0) {
          //if inventory is 0, set price as 0
          total_price.value = 0
        }
        
        loading_price.value = false
      })
      .catch((err) => {
        loading_price.value = false
      })
    }

    //iterate through item list and group them into a dictionary list, group name is key.
    /* the returned components variable is like this: 
    {
      "chassis": {"group_name": "chassis",
                  "group_id": 1,
                  "select": true,
                  "types": {"Micro-ATX": {"type_name": "Micro-ATX",
                                          "type_id": 2,
                                          "items": [
                                                    {"item_id": 2169,
                                                    "group": "CPU",
                                                    "group_id": 2,
                                                    "type": "Core i7",
                                                    "type_id": 5,
                                                    "itmno": "I7-12700KB",
                                                    "addon_itmno": "CA-GAMGTV2",
                                                    "price_system": 0.0,
                                                    "title": "Intel Core i7 12700K / 3.6 GHz processor - Box (without cooler)",
                                                    "brand": "Intel",
                                                    "price": 397.0,
                                                    "balance": 68,
                                                    "allow_multi_qty": false,
                                                    "addon_item": {...},
                                                    "selected": true,
                                                    "qty": 0
                                                    },
                                                    ...
                                                   ]
                                          },
                            ...
                           }
                  },
      ...
    }
    */
    function get_component_list(item_list){

      //get add-on items' itmno list
      var addon_items = []
      for (let i = 0; i < item_list.length; i++) {
        if (item_list[i].addon_itmno) {
          addon_items.push(item_list[i].addon_itmno)
        }
      }
      
      var components = {}
      var first = null
      for (let i = 0; i < item_list.length; i++) {
        //skip addon items
        if (addon_items.indexOf(item_list[i].itmno) >= 0) continue

        //add two more fields: selected, qty
        item_list[i]["selected"] = false
        item_list[i]["qty"] = 1

        first = false
        //first item in each group
        if (components.hasOwnProperty(item_list[i].group) == false) {
          first = true //this is the first item in the group
          
          components[item_list[i].group] = {
            "group_name":item_list[i].group, 
            "group_id":item_list[i].group_id, 
            "select":false, 
            "types":{}}
          

        }

        // components[item_list[i].group]["group_name"] = item_list[i].group
        // components[item_list[i].group]["group_id"] = item_list[i].group_id

        //set first item as selected and initilize qty to 1
        if (components[item_list[i].group]["select"] == false) {
          if (item_list[i]["balance"] > 0) {
            components[item_list[i].group]["select"] = true
            
            item_list[i]["qty"] = 1
            item_list[i]["selected"] = true

            //add first item to selected item list
            selected_items.push(item_list[i])
          }
        }

        //first type in type list
        if (components[item_list[i].group]["types"].hasOwnProperty(item_list[i].type) == false) {
          components[item_list[i].group]["types"][item_list[i].type] = {"type_name":null, "type_id":null, "items":[]}
        }
        components[item_list[i].group]["types"][item_list[i].type]["type_name"] = item_list[i].type
        components[item_list[i].group]["types"][item_list[i].type]["type_id"] = item_list[i].type_id

        //add item list in the following 3 steps:
        //1. add addon_item key
        item_list[i]["addon_item"] = {}
        if (item_list[i].addon_itmno) item_list[i]["addon_item"] = component_items[item_list[i].addon_itmno]
        
        //2. if it is first item's addon item, set addon item's qty to 1
        if (first) item_list[i]["addon_item"]["qty"] = 1
        else item_list[i]["addon_item"]["qty"] = 1
        
        //3. add all other item info
        components[item_list[i].group]["types"][item_list[i].type]["items"].push(item_list[i]) 

      }

      //Check if each group have selected an option in case all the items in a group is out of balance.
      //In this situatioin, set the first item to be selected.
      for (const [key, group] of Object.entries(components)) { //loop through the list
        
        //found the group that has no item selected
        if (components[key]["select"] == false) { 

          //set the first item in the group to be selected and add it to selected_items list.
          let firsttype = Object.keys(components[key]["types"])[0]
          components[key]["types"][firsttype]["items"][0]["selected"] = true
          components[key]["types"][firsttype]["items"][0]["qty"] = 1
          selected_items.push(components[key]["types"][firsttype]["items"][0])

          components[key]["select"] == true
        }
      }
      
      return components
    }

    //insert each group list item into a dictionary called groups. group name is the key
    function get_group_list(group_list) {
      var groups = {}
      for (let i = 0; i < group_list.length; i++) {
        groups[group_list[i].group_name] = group_list[i]
      }

      return groups
    }

    //get images
    function get_images(image_str){
      var lt = []
      var image_list = image_str.split(",")
      for (var i = 0; i<image_list.length; i++){
        lt.push({"url": image_list[i]})
      }
      return lt
    }

    //deselect all items and reset qty to 1 within a group
    function deselect_all(group) {
      for (let type in components.value[group].types) {
        for (var i = 0; i < components.value[group].types[type].items.length; i++) {
          components.value[group].types[type].items[i].selected = false
          components.value[group].types[type].items[i].qty = 1

          if (components.value[group].types[type].items[i].addon_itmno) {
            components.value[group].types[type].items[i].addon_item.qty = 1
          }
        }
      }
    }
    
    //find if all items in the group are unchecked. Return true if all are unchecked.
    function is_all_unchecked(group){
      var flag = true
      $('input[name="'+group+'"]').each(function(){
        if ($(this).prop("checked")== true) flag = false
      })
      return flag
    }

    //
    function check_group_max_qty(current_item, current_item_qty){
      
      //get max group qty
      let max_group_qty = group_list.value[current_item.group].max_qty
    
      //if max group qty is 0, there is no limit, just return true
      if (max_group_qty == 0) return true

      //else if there is max group qty, calculate the toal qty
      var qty
      var total_qty = parseInt(current_item_qty)
      $('input[name="'+current_item.group+'"]').each(function(){
        if ($(this).prop("checked") == true) {
          qty = $("#qty_input_" + $(this).val()).val()
          if ($(this).attr("id") != current_item.item_id) total_qty = total_qty + parseInt(qty)
        }
      })
      
      //if total qty is more than max group qty, return false
      if (total_qty > max_group_qty) return false
      else return true
    }

    
    //find item in components by itmno
    function find_item(item_id) {
      for (let group in components.value) {
        for (let type in components.value[group].types) {
          for (var i = 0; i < components.value[group].types[type].items.length; i++) {
            //if (components.value[group].types[type].items[i].itmno == itmno) {
            if (components.value[group].types[type].items[i].item_id == item_id) {
              return components.value[group].types[type].items[i]
            }
          }
        }
      }
      return null
    }

    //change the selected items list and re-calculate the price
    const onRadioChange = (group, item_id) => {
      //deselect all items in the group and reset the qty to 1 for all
      deselect_all(group)

      //find current selected item and set selected to true, qty to 1
      let item = find_item(item_id)
      item.selected = true
      item.qty = 1

      //retrieve inventory and price
      if (isAuthenticated == true) get_inventory_and_price()

    }
  
    //change the selcted items list and re-calculate the price
    function onCheckboxChange(group, item_id){
      
      //find current selected item and set selected to true or false 
      //according to whether it is checked or unckecked, set qty to 1
      let item = find_item(item_id)
      item.qty = 1
      if (event.target.checked)
        item.selected = true
      else
        item.selected = false
      
      //if it is the only item selected in the group, then force this item to be selected, 
      //you cannot uncheck it because it has to be at least one item selected in a group
      if (is_all_unchecked(group) == true) {
        item.selected = true
        $("#"+item_id).prop("checked", true)
      }

      //check the group max qty for all selected items (it will return false if the total qty is
      // beyound max qty, in this case, set the item's qty to be 1)
      if (check_group_max_qty(item, 1) == false) {
        item.qty = 1  
      }

      //retrieve inventory and price
      if (isAuthenticated == true) get_inventory_and_price()
    }

    function handleIncreaseQty(item_id) {
      //find the item and set qty for it
      var item
      item = find_item(item_id)

      //increase qty by 1
      var qty = $("#qty_input_" + item_id).val()
      qty = parseInt(qty) + 1

      //check group max qty for all selected items
      if (check_group_max_qty(item, qty) == false) {
        return 0
      }

      //check inventory. It cannot increase beyound inventory
      if (qty <= item.balance){
        if (item.addon_itmno) {
          if (qty <= item.addon_item.balance) {
            item.addon_item.qty = qty
            $("#qty_input_" + item_id).val(qty)
            item.qty = qty
          }
        }
        else {
          $("#qty_input_" + item_id).val(qty)
          item.qty = qty
        }
      }

      //retrieve inventory and price
      if (isAuthenticated == true) get_inventory_and_price()
      
    }

    function handleDecreaseQty(item_id) {
      //decrease qty by 1
      var qty = $("#qty_input_" + item_id).val()
      var qty = parseInt(qty)-1
      if (qty == 0) qty = 1
      $("#qty_input_" + item_id).val(qty)

      //find the item and set qty for it
      var item
      item = find_item(item_id)
      item.qty = qty
      if (item.addon_itmno) {
        item.addon_item.qty = qty
      }

      //retrieve inventory and price
      if (isAuthenticated == true) get_inventory_and_price()
    }

    //This function is for optinal groups
    function handleUnselectAll(group) {
      deselect_all(group)

      //retrieve inventory and price
      if (isAuthenticated == true) get_inventory_and_price()
    }

    //add item into po cart
    const handleAddToCart = async () => {
      if (check_qty() == false) return 0
      
      error.value = ""
      message.value = ""
      waiting_add.value = true

      var payload = generate_payload()
      
      try {
        //add to cart
        let result = await store.dispatch("addToCart", payload)
        message.value = result["message"]
        waiting_add.value = false

        //update cart quantity page on header
        context.emit("updateCartQty");

        //log access (save this user action into log system)
        try {
          if (store.getters.isCustomer) {
              let payload = {
                'action':'add to cart',
                'po_cart_id': result["data"]["po_cart_id"],
                'po_cart_itmno': result["data"]["itmno"],
                'po_cart_branch': result["data"]["branch"],
                'po_cart_quantity': result["data"]["quantity"],
              }
              let result2 = await store.dispatch('logAccess', payload)
          }
        }
        catch {//do nothing when error happends to logging
        }

      } catch (err) {
        waiting_add.value = false

        try{
          error.value = err
        }
        catch {
          error.value = "Something is wrong."
        }
      }
      
    }

    function check_qty() {
      if (quantity.value === null) {
        error.value = "Please enter a quantity."
        return false
      }
      if (isInteger(quantity.value) == false) {
        error.value = "Please enter a valid quantity."
        return false
      }
      return true
    }

    function generate_payload() {
      var payload = {}
      payload["itmno"] = item.value.itmno
      payload["quantity"] =  quantity.value
      payload["system"] = true
      payload["system_details"] = {}
      payload["system_details"]["system_id"] = item.value.id
      payload["system_details"]["item_list"] = generate_payload_item_list()
      
      let tmp = JSON.stringify(payload["system_details"])
      payload["system_details"] = tmp
      return payload
    }

    function generate_payload_item_list() {
      var item_list = []
      for (let group in components.value) {
        for (let type in components.value[group].types) {
          for (var i = 0; i < components.value[group].types[type].items.length; i++) {
            if (components.value[group].types[type].items[i].selected) {
              //add selected item into payload
              item_list.push(
                {"group_id": components.value[group].types[type].items[i].group_id,
                "item_id": components.value[group].types[type].items[i].item_id,
                "itmno": components.value[group].types[type].items[i].itmno,
                "qty": components.value[group].types[type].items[i].qty,
                "addon": false
              })

              //if there is addon item, add it into payload
              let found = false
              if (components.value[group].types[type].items[i].addon_itmno) {
                for (let j = 0; j < item_list.length; j++) {
                  if (item_list[j].itmno == components.value[group].types[type].items[i].addon_itmno) {
                    item_list[j].qty = item_list[j].qty + components.value[group].types[type].items[i].addon_item.qty
                    found = true
                    break
                  }
                }
                if (found == false) {
                  //for addon item, item_id is the id of the item that the addon item added to
                  item_list.push(
                    {"group_id": component_items[components.value[group].types[type].items[i].addon_itmno].group_id,
                    "item_id":components.value[group].types[type].items[i].item_id,
                    "itmno": components.value[group].types[type].items[i].addon_itmno,
                    "qty": components.value[group].types[type].items[i].addon_item.qty,
                    "addon": true
                  })
                }
              } 
              
            }
          }
        }
      }
      return item_list
    }
    
    const handleGetQuote = async () => {
      var payload = generate_payload()
      router.push({name: 'ConfigQuote', query: { itmno: item_no }, params: {item:  JSON.stringify(item.value), components: JSON.stringify(components.value), config_payload: JSON.stringify(payload)}})
    }

    function handleLogin () {
      router.push({name: 'Login'})
    }

    return {
        loading,
        waiting_quote,
        waiting_add,
        loading_price,
        breadcrumb_links,
        images,
        error,
        message,
        is_customer,
        is_auth,
        quantity,
        components,
        group_list,
        item,
        formatPrice,
        onRadioChange,
        onCheckboxChange,
        total_price,
        inventory,
        handleAddToCart,
        handleGetQuote,
        handleIncreaseQty,
        handleDecreaseQty,
        handleUnselectAll,
        handleLogin,
    }
  }
}
</script>

<style scoped>
.container {min-height: 500px;}
#item {
  margin-top: 15px;
}

.label {
  color: #444;
}
li {
  margin: 5px 0;
}
.mktDescription {
  padding: 0px 40px 20px 40px;
  /* height: 28px; */
  color:#444;
}
.title{
  list-style: none;
  padding: 0px 40px 20px 40px;
  height: 38px;
  color:#444;
  background-color:	#F5F5F5;
  margin-top:20px;
  font-size:25px;
  font-weight: bold;

}
#cart_btn {
  width: 100%;
}
.nav-link{
    color:blue
}

#cartTable {
  border: #ccc 1px solid;
  border-radius: 5px;
  border-collapse: separate;
  width:100%;
  padding:20px;
}
#nav-tab button.active{
  border-bottom: 8px solid #4d88e8;
  color:#444;
  font-weight: bold;
}
#nav-tab button{
  color:grey;
}
/* #cartTable td{
  padding: 10px;

} */

#carousel_big_image {
  margin-top: 5%;
}
#carousel_small_images {
  position: relative; margin-top:60px;
}
#small_images {
  position:relative;margin-top:-25px;
  flex-wrap: wrap;
    justify-content: center; 
    padding: 0 50px; 
    width: 100%; 
    position: static; 
    right: 0; 
    bottom: 0; 
    margin-right: 0; 
    margin-left: 0;
}
.indent {
  text-indent: -30px;
  padding-left: 30px;
}
.icon {
  padding-left:10%;
  font-size:1.5rem;
  color:black;
}
a {
  color: grey;
  text-decoration-line: underline;
}
a:hover{
  color:cornflowerblue;
  text-decoration: underline;
}
.review_color a{
   color: #1ed2e9;
}
.rma{
   padding:0px 40px 20px 40px;
}
.nav-link1 {
  margin-bottom:-6px !important;
  
  }
.config_type {
  width: 150px; 
}
.qty_input {
    width:70px;
    padding:5px;
    height:25px;
    font-size:12px;
}
#stock td, #stock th {padding-left:8px !important; padding-right:8px !important;}
.add_button {background-color: #e56600 !important; border-color: #e56600 !important;}
.add_button:hover {background-color: #cc5c02 !important; border-color: #cc5c02 !important;}
.quote_button {display:block !important;background-color: #265197 !important; border-color: #265197 !important;}
.quote_button:hover {display:block !important;background-color: #0b2d63 !important; border-color: #0b2d63 !important;}
.spec_bar {background-color:#f3f3f3 !important;}
.qty_input_sm {width:50px; border:1px solid #ccc !important; text-align: center;}
.qty_btn {background-color: #ccc; border:1px solid #ccc;}
.qty_btn:hover {background-color: #888; color:#fff; border:1px solid #888;}
.qty_btn:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  background-color: #ccc; border:1px solid #ccc;
  color:#000;
}
.big {font-size: 15px; font-weight: bold;}
.price_container {
  background-color:#fff;
    border: #ccc 1px solid;
    border-radius: 5px;
    width:250px;
    padding:20px;
}
.outofstock_flag1 {
  display: inline-block;
  text-decoration: underline;
  margin-left:20px;
  font-weight: bold;
  color:#888;
}
.outofstock_flag {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #fada5e;
  margin-left:10px;
  padding:2px 2px;
  line-height: 0;
  font-size: 12px;
  background-color: #fffdd0;
}
.outofstock_flag i {
  /* color: #ffcc00; */
  color: #fca510;
  margin:0;
}
.spec {
  white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}
.blue {color:#265197 !important;}
@media only screen and (min-width: 600px) {
  
}
.sticky {
    position:fixed;
    right: 40px;
    /* top:0;*/
    /* width:250px; */
    z-index:100; 
  }

.grey{
  color:#555;
}
.font_small {font-size: 25px; font-weight: bold;}
.pointer {cursor: pointer;}
</style>
